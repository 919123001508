var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-row',[(!_vm.loggedInNamespace && _vm.isHosted)?_c('v-col',[_c('v-btn',{staticClass:"v-btn--active float-right mr-3",attrs:{"text":"","small":""},on:{"click":_vm.addNamespace}},[_vm._v(" Add Namespace ")])],1):_c('v-col',[_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayMenu),expression:"displayMenu"}],attrs:{"close-on-content-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.loggedInNamespace),expression:"loggedInNamespace"}],staticClass:"float-right",on:{"click":_vm.openMenu}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-server ")]),_vm._v(" "+_vm._s(_vm.namespace.name)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-card',[_c('v-subheader',[_vm._v("Tenant ID")]),_c('v-list',{staticClass:"pt-0 pb-0 mx-2"},[_c('v-list-item',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-chip',[_c('v-list-item-title',[_c('span',{attrs:{"data-test":"tenantID-text"}},[_vm._v(_vm._s(_vm.tenant))])]),_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.tenant),expression:"tenant"},{name:"clipboard",rawName:"v-clipboard:success",value:(function () {
                      this$1.$store.dispatch('snackbar/showSnackbarCopy', this$1.$copy.tenantId);
                    }),expression:"() => {\n                      this.$store.dispatch('snackbar/showSnackbarCopy', this.$copy.tenantId);\n                    }",arg:"success"}],attrs:{"right":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"pt-0"},[_c('v-subheader',[_vm._v("Namespaces")]),_c('v-list-item-group',[_c('v-virtual-scroll',{attrs:{"height":150,"item-height":"50","items":_vm.availableNamespaces},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var item = ref.item;
return [_c('v-list-item',{key:item.tenant_id,on:{"click":function($event){return _vm.switchIn(item.tenant_id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-login")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}])})],1)],1),_c('v-divider'),_c('v-list',{staticClass:"pt-0 pb-0"},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHosted),expression:"isHosted"}],on:{"click":function($event){_vm.dialog=!_vm.dialog}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus-box")])],1),_c('v-list-item-content',[_vm._v(" Create Namespace ")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":"/settings/namespace-manager"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-content',[_vm._v(" Settings ")])],1)],1)],1)],1)],1),_c('NamespaceAdd',{attrs:{"show":_vm.dialog,"first-namespace":_vm.first},on:{"update:show":function($event){_vm.dialog=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }